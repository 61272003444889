import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Tab, 
  Tabs, 
  TextField, 
  Select, 
  MenuItem, 
  FormControl,
  InputLabel,
  Typography,
  Chip,
  Paper,
  Container,
  Button,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import questions from '../../utils/questions-consts.json';

// Custom styled components
const StyledTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fe6d29',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#fe6d29',
    },
    '&:hover fieldset': {
      borderColor: '#fe6d29',
    },
  },
});

const StyledSelect = styled(Select)({
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fe6d29',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fe6d29',
  },
});

const StyledInputLabel = styled(InputLabel)({
  '&.Mui-focused': {
    color: '#fe6d29',
  },
});

const StyledChip = styled(Chip)(({ theme }) => ({
  '&.MuiChip-colorPrimary': {
    backgroundColor: '#fe6d29',
  },
  '&:hover': {
    backgroundColor: '#e55d1a',
  },
}));

const SubmitButton = styled(Button)({
  backgroundColor: '#fe6d29',
  color: 'white',
  padding: '10px 30px',
  '&:hover': {
    backgroundColor: '#e55d1a',
  },
  marginTop: '2rem',
  marginBottom: '3rem',
});

const SuggestButton = styled(Button)({
  backgroundColor: 'transparent',
  color: '#fe6d29',
  border: '1px solid #fe6d29',
  padding: '5px 15px',
  fontSize: '0.875rem',
  marginTop: '8px',
  '&:hover': {
    backgroundColor: 'rgba(254, 109, 41, 0.1)',
  },
});

const QuestionInput = ({ question, value, onChange }) => {
  const [isSuggesting, setIsSuggesting] = useState(false);
  const [suggestCount, setSuggestCount] = useState(0);

  const handleSuggest = async () => {
    setIsSuggesting(true);
    
    const prompt = suggestCount === 0 
      ? `Suggest a creative and detailed answer for this character questionnaire question: "${question.label}"`
      : `Suggest another different creative answer for the same question: "${question.label}". Previous answer was: ${value}`;

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_API_kEY}`
        },
        body: JSON.stringify({
          model: 'gpt-3.5-turbo',
          messages: [
            {
              role: 'system',
              content: 'You are a creative writing assistant helping to develop detailed character profiles. Provide specific, unique suggestions.'
            },
            { role: 'user', content: prompt }
          ]
        })
      });

      const data = await response.json();
      const suggestion = data.choices[0].message.content;

      const newValue = suggestCount === 0 
        ? suggestion 
        : `${value}\n\nAlternative suggestion:\n${suggestion}`;

      onChange(question.id, newValue);
      setSuggestCount(prev => prev + 1);
    } catch (error) {
      console.error('Error getting suggestion:', error);
    } finally {
      setIsSuggesting(false);
    }
  };

  const renderSuggestButton = () => (
    <SuggestButton
      onClick={handleSuggest}
      disabled={isSuggesting}
      startIcon={isSuggesting ? <CircularProgress size={16} color="inherit" /> : null}
    >
      {suggestCount === 0 ? 'Suggest me' : 'Suggest me more'}
    </SuggestButton>
  );

  switch (question.type) {
    case 'input':
      return (
        <StyledTextField
          fullWidth
          label={question.label}
          value={value || ''}
          onChange={(e) => onChange(question.id, e.target.value)}
          required={question.required}
          margin="normal"
        />
      );
    
    case 'textarea':
      return (
        <Box>
          <StyledTextField
            fullWidth
            multiline
            rows={4}
            label={question.label}
            value={value || ''}
            onChange={(e) => onChange(question.id, e.target.value)}
            required={question.required}
            margin="normal"
          />
          {renderSuggestButton()}
        </Box>
      );
    
    case 'select':
      return (
        <FormControl fullWidth margin="normal">
          <StyledInputLabel>{question.label}</StyledInputLabel>
          <StyledSelect
            value={value || ''}
            onChange={(e) => onChange(question.id, e.target.value)}
            required={question.required}
            label={question.label}
          >
            {question.options.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      );
    
    case 'chips':
      return (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="body1" sx={{ mb: 1 }}>{question.label}</Typography>
          <Paper sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, p: 1 }}>
            {question.options.map((option) => {
              const isSelected = value?.includes(option);
              return (
                <StyledChip
                  key={option}
                  label={option}
                  onClick={() => {
                    const newValue = isSelected
                      ? (value || []).filter(v => v !== option)
                      : [...(value || []), option];
                    onChange(question.id, newValue);
                  }}
                  color={isSelected ? "primary" : "default"}
                  sx={{ m: 0.5 }}
                />
              );
            })}
          </Paper>
        </Box>
      );
    
    case 'date':
      return (
        <StyledTextField
          fullWidth
          type="date"
          label={question.label}
          value={value || ''}
          onChange={(e) => onChange(question.id, e.target.value)}
          required={question.required}
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />
      );
    
    default:
      return null;
  }
};

const QuestionSection = ({ section, values, onChange }) => (
  <Box sx={{ mb: 4 }}>
    <Typography variant="h6" sx={{ mb: 2, color: '#fe6d29' }}>
      {section.title}
    </Typography>
    {section.questions.map((question) => (
      <QuestionInput
        key={question.id}
        question={question}
        value={values[question.id]}
        onChange={onChange}
      />
    ))}
  </Box>
);

const Questionnaire = () => {
  const [tab, setTab] = useState(0);
  const [values, setValues] = useState({});

  const handleChange = (questionId, value) => {
    setValues(prev => ({
      ...prev,
      [questionId]: value
    }));
  };

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const handleSubmit = () => {
    console.log('Form Values:', values);
    // Add your submit logic here
  };

  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      <Typography variant="h4" sx={{ mb: 4, color: '#fe6d29', textAlign: 'center' }}>
        Character Questionnaire
      </Typography>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
        <Tabs 
          value={tab} 
          onChange={handleTabChange}
          centered
          sx={{
            '& .MuiTab-root.Mui-selected': {
              color: '#fe6d29',
            },
            '& .MuiTabs-indicator': {
              backgroundColor: '#fe6d29',
            },
          }}
        >
          <Tab label="Basic Information" />
          <Tab label="Advanced Details" />
        </Tabs>
      </Box>

      {tab === 0 && (
        <Box component="form">
          {Object.entries(questions.basic).map(([key, section]) => (
            <QuestionSection
              key={key}
              section={section}
              values={values}
              onChange={handleChange}
            />
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SubmitButton 
              variant="contained"
              onClick={handleSubmit}
            >
              Save Basic Information
            </SubmitButton>
          </Box>
        </Box>
      )}

      {tab === 1 && (
        <Box component="form">
          {Object.entries(questions.advanced).map(([key, section]) => (
            <QuestionSection
              key={key}
              section={section}
              values={values}
              onChange={handleChange}
            />
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <SubmitButton 
              variant="contained"
              onClick={handleSubmit}
            >
              Save Advanced Information
            </SubmitButton>
          </Box>
        </Box>
      )}
    </Container>
  );
};

export default Questionnaire; 