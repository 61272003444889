import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Drawer } from '@mui/material';
import './components/LeftDrawer/LeftDrawer.css';  // Import your existing CSS for styling
import './ChatBot.css'
import { RiCopilotFill } from "react-icons/ri";
import { Editor } from 'slate';

const apiKey = process.env.REACT_APP_API_kEY || '';

const Chatbot = ({ screenplayContent, updateScriptApi, script, editor, Transforms }) => {
  const [userMessage, setUserMessage] = useState('');
  const [messages, setMessages] = useState([{ text: 'Hi there 👋\nHow can I help you today?', type: 'incoming' }]);
  const [isChatbotVisible, setIsChatbotVisible] = useState(false);
  const chatInputRef = useRef(null);
  const chatboxRef = useRef(null);

  useEffect(() => {

    if (script?.coWriter && script?.coWriter.length > 0) {
      setMessages(script?.coWriter);
    }

  }, [script]);

  const handleMessageChange = (e) => {
    setUserMessage(e.target.value);
  };



  // Function to extract text from the screenplayContent (Slate.js structure)
  const extractTextFromScreenplay = (content) =>
    content
      .map((node) => {
        if (node.children && node.children.length > 0) {
          return extractTextFromScreenplay(node.children);  // Recursive call for nested children
        }
        return node.text || ''; // Extract the text from each node
      })
      .join('\n') // Join the extracted text with new lines
    ;


  const handleSendMessage = async () => {
    if (!userMessage.trim()) return;

    // Add the outgoing message first
    const newMessage = { text: userMessage, type: 'outgoing' };

    setMessages((prevMessages) => {
      const updatedMessages = [...prevMessages, newMessage];

      // After updating the state, call updateScriptApi with the updated messages
      updateScriptApi({ coWriter: updatedMessages });

      return updatedMessages; // Return the updated messages to update the state
    });

    setUserMessage('');

    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;

    setMessages((prevMessages) => [
      ...prevMessages,
      { text: 'Thinking...', type: 'incoming' }
    ]);
    chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;

    try {
      // Extract text from screenplayContent
      const screenplayText = extractTextFromScreenplay(screenplayContent);

      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-3.5-turbo',
          messages: [
            { role: 'system', content: 'You are a helpful screenplay co-writer.' },
            { role: 'user', content: `${screenplayText}\n${userMessage}` }
          ]
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${apiKey}`
          }
        }
      );

      const { data } = response;
      if (response.status !== 200) throw new Error(data.error.message);

      // Add the response from ChatGPT as an incoming message
      const chatResponse = { text: data.choices[0].message.content, type: 'incoming' };

      setMessages((prevMessages) => {
        const updatedMessages = [
          ...prevMessages.slice(0, prevMessages.length - 1), // Remove the "Thinking..." message
          chatResponse
        ];

        // Update the state and call updateScriptApi with the updated messages
        updateScriptApi({ coWriter: updatedMessages });

        return updatedMessages; // Return updated messages for state update
      });
    } catch (error) {
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, prevMessages.length - 1),
        { text: error.message, type: 'incoming error' }
      ]);
    }
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const toggleChatbot = () => {
    setIsChatbotVisible(!isChatbotVisible);
  };

  const moveToPage = (page) => {
    Transforms.insertNodes(
      editor,
      { type: 'action', children: [{ text: page, uppercase: false }] },
      { at: Editor.end(editor), select: true }
    );
  }


  return (
    <>
      <button className="chatbot-toggler" onClick={toggleChatbot}>
        {/* <span className="material-symbols-rounded">mode_comment</span> */}
        {/* <span className="material-symbols-outlined">close</span> */}
        <RiCopilotFill
          size={30}
          color="#fff"
        />
      </button>

      {isChatbotVisible && (
        <Drawer
          anchor="left"
          open={isChatbotVisible}
          onClose={toggleChatbot}
        >
          <div className="chatbot">
            <header>
              <h2>Co-Writer</h2>
              <span
                className="close-btn material-symbols-outlined"
                onClick={toggleChatbot}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    toggleChatbot();  // Trigger close on Enter or Space key
                  }
                }}
                role="button"
                aria-label="Close chatbot"
                tabIndex="0"
              >
                close
              </span>

            </header>

            <ul className="chatbox" ref={chatboxRef}>
              {messages.map((message, index) => (
                <li key={index} className={`chat ${message.type}`}>
                  {message.type === 'outgoing' ? (
                    <p>{message.text}</p>
                  ) : (
                    <>
                      {/* <span className="material-symbols-outlined">smart_toy</span> */}
                      <span>

                        <RiCopilotFill
                          size={20}
                          color="#fff"
                          style={{ marginTop: '5px' }}
                        />
                      </span>

                      <p className='message-response'>
                        {!["Thinking...", "Hi there 👋\nHow can I help you today?", "Hello! How can I help you today?"].includes(message.text) && (
                          <button
                            className="move-to-page"
                            onClick={() => moveToPage(message.text)}
                          >
                            Move to Page
                          </button>
                        )}
                        {message.text}</p>
                    </>
                  )}
                </li>
              ))}
            </ul>

            <div className="chat-input">
              <textarea
                ref={chatInputRef}
                value={userMessage}
                onChange={handleMessageChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter a message..."
                spellCheck="false"
                required
                aria-label="Chat message input"
              />
              <span
                className="material-symbols-rounded"
                onClick={handleSendMessage}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    handleSendMessage();  // Trigger send on Enter or Space key
                  }
                }}
                role="button"
                aria-label="Send message"
                tabIndex="0"  // Makes the span focusable, enabling keyboard interaction
              >
                send
              </span>

            </div>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default Chatbot;
